<template>
  <DataAssetSubHeader
    :step-number="2"
    title="Set up and run preprocess"
    description="Configure settings for asset columns and the preprocess task."
  >
    <template #descriptionExtra>
      <NButton
        tag="a"
        text
        class="text-primary !hover:underline"
        type="primary"
        size="tiny"
      >
        <template #icon>
          <div class="i-solar-square-top-down-line-duotone" />
        </template>
        <span>Raw Data</span>
      </NButton>
    </template>
    <template #actions>
      <div class="flex items-center gap-2">
        <NButton type="tertiary" quaternary>
          <template #icon>
            <div class="i-solar-diskette-line-duotone" />
          </template>
          Saved
        </NButton>
        <NButton type="primary">
          <template #icon>
            <div class="i-solar-play-circle-line-duotone" />
          </template>
          Run Preprocess
        </NButton>
      </div>
    </template>
  </DataAssetSubHeader>
  <section class="container space-y-4">
    <div class="space-y-2">
      <DataAssetPreprocessSettings />
    </div>
  </section>
</template>
