<script setup lang="ts">
import {type DefineComponent} from 'vue'
import {usePath} from '@level3/composables/usePath.ts'
import type {AssetType} from '@level3/types/asset'
import DataAssetProperties from '@level3/components/DataAssetProperties/DataAssetProperties.vue'
import DataAssetPreprocess from '@level3/components/DataAssetPreprocess/DataAssetPreprocess.vue'
import DataAssetDataJoining from '@level3/components/DataAssetDataJoining/DataAssetDataJoining.vue'
import DataAssetTransformations from '@level3/components/DataAssetTransformations/DataAssetTransformations.vue'
import DataAssetFeatures from '@level3/components/DataAssetFeatures/DataAssetFeatures.vue'

const {dataAssetSlug} = usePath()

// Define the Tab interface
type Tab = {
  name: string
  icon: string
  title: string
  component: DefineComponent
}

// TODO: get data assets from api once ConMan SDK is integrated
const options = ref([
  {
    label: 'data_asset_1',
    value: 'data-asset-1',
  },
  {
    label: 'data_asset_2',
    value: 'data-asset-2',
  },
])
const assetType = ref<AssetType>('customer_population')

const activeTab = useRouteQuery<string>('tab')

// Type the tabs array using the Tab interface
const tabs: Tab[] = [
  {
    name: 'properties',
    icon: 'i-solar-pen-new-square-outline',
    title: 'Properties',
    component: DataAssetProperties as DefineComponent,
  },
  {
    name: 'preprocess',
    icon: 'i-solar-clipboard-check-outline',
    title: 'Preprocess',
    component: DataAssetPreprocess as DefineComponent,
  },
  {
    name: 'data-joining',
    icon: 'i-solar-link-minimalistic-outline',
    title: 'Data Joining',
    component: DataAssetDataJoining as DefineComponent,
  },
  {
    name: 'transformations',
    icon: 'i-solar-clipboard-add-outline',
    title: 'Transformations',
    component: DataAssetTransformations as DefineComponent,
  },
  {
    name: 'features',
    icon: 'i-solar-server-outline',
    title: 'Features',
    component: DataAssetFeatures as DefineComponent,
  },
]
</script>

<template>
  <div class="mx-auto flex flex-col gap-6 py-9 container">
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-3">
        <div class="w-40">
          <NSelect
            v-model:value="dataAssetSlug"
            class="override-ghost"
            :options="options"
            width="200px"
          />
        </div>
        <NTag
          v-if="assetType === 'customer_population'"
          :bordered="false"
          class="bg-gray-50"
          :round="true"
        >
          Customer population asset
        </NTag>
        <NTag
          v-else-if="assetType === 'engagement'"
          :bordered="false"
          class="bg-gray-50"
          :round="true"
        >
          Engagement asset
        </NTag>
      </div>
      <div class="flex gap-2 text-sm text-gray-500">
        <div class="i-solar-calendar-minimalistic-outline" />
        <p class="text-base">Last updated: -</p>
      </div>
    </div>
    <NTabs v-model:value="activeTab" type="line" animated>
      <NTabPane
        v-for="tab in tabs"
        :key="tab.name"
        :name="tab.name"
        class="mb-4"
        :tab="
          h('div', {class: 'flex items-center gap-1'}, [
            h('span', {class: tab.icon}),
            h('p', tab.title),
          ])
        "
      >
        <component :is="tab.component" class="tab-content" />
      </NTabPane>
    </NTabs>
  </div>
</template>
