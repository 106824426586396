<script setup lang="ts">
import {useForm} from 'vee-validate'
import {toTypedSchema} from '@vee-validate/zod'
import {z} from 'zod'
import {type SelectOption} from 'naive-ui'
import DataAssetPreprocessDataUpdateTypeSelect from './DataAssetPreprocessDataUpdateTypeSelect.vue'

type Settings = {
  customerIdColumnOptions: SelectOption[]
  primaryKeyColumnOptions: SelectOption[]
  partitionColumnOptions: SelectOption[]
}

type Props = {
  settings: Settings
}

withDefaults(defineProps<Props>(), { settings: () => ({
    customerIdColumnOptions: [],
    primaryKeyColumnOptions: [],
    partitionColumnOptions: [],
  }), })

const dataAssetPreprocessSettingsSchema = z.object({
  dataUpdateType: z.enum(['incremental', 'snapshot']).default('incremental'),
  customerIdColumn: z.string().min(1),
  primaryKeyColumns: z
    .array(z.string())
    .min(1, 'Please select at least one primary key column'),
  partitionColumn: z.string().min(1),
  daysBackToEnforcePrimaryKey: z.number().min(1).max(10_000).default(1),
  useRecentTimestamp: z.boolean(),
})

type FormValues = z.infer<typeof dataAssetPreprocessSettingsSchema>
const formValues = defineModel<FormValues>('formValues', {
  required: true,
})

const {defineField} = useForm({
  validationSchema: toTypedSchema(dataAssetPreprocessSettingsSchema),
  initialValues: formValues.value,
})

const naiveConfig = (state: {errors: string[]; required: boolean}) => ({
  props: {
    validationStatus: state.errors[0] ? ('error' as const) : undefined,
    feedback: state.errors[0] as string | undefined,
    required: state.required,
  },
})

const [dataUpdateType, dataUpdateTypeProps] = defineField(
  'dataUpdateType',
  naiveConfig,
)
const [customerIdColumn, customerIdColumnProps] = defineField(
  'customerIdColumn',
  naiveConfig,
)
const [primaryKeyColumns, primaryKeyColumnsProps] = defineField(
  'primaryKeyColumns',
  naiveConfig,
)
const [partitionColumn, partitionColumnProps] = defineField(
  'partitionColumn',
  naiveConfig,
)
const [daysBackToEnforcePrimaryKey, daysBackToEnforcePrimaryKeyProps] =
  defineField('daysBackToEnforcePrimaryKey', naiveConfig)
const [useRecentTimestamp, useRecentTimestampProps] = defineField(
  'useRecentTimestamp',
  naiveConfig,
)
</script>

<template>
  <NCard class="preprocess-settings-card-card flex flex-col shadow @container">
    <section class="preprocess-settings-card-top-section">
      <div
        class="grid grid-cols-1 gap-6 @2xl:grid-cols-4 @md:grid-cols-2 @xl:grid-cols-3"
      >
        <NFormItem
          path="dataUpdateType"
          label="Data update type"
          v-bind="dataUpdateTypeProps"
        >
          <DataAssetPreprocessDataUpdateTypeSelect
            v-model="dataUpdateType"
            class="w-full"
          />
        </NFormItem>

        <NFormItem path="customerIdColumn" v-bind="customerIdColumnProps">
          <template #label>
            <LabelWithTooltip label="Column with customer IDs" />
          </template>
          <NSelect
            v-model:value="customerIdColumn"
            :options="settings.customerIdColumnOptions"
            placeholder="Select column"
          />
        </NFormItem>

        <NFormItem path="primaryKeyColumns" v-bind="primaryKeyColumnsProps">
          <template #label>
            <LabelWithTooltip
              label="Primary key columns"
              tooltip="Column or columns which constitute a primary key in this asset. A column (or set of columns) is a primary key if no two rows should contain the exact same combination of the column(s). Primary key column(s) are required for the customer population asset."
            />
          </template>
          <NSelect
            v-model:value="primaryKeyColumns"
            :options="settings.primaryKeyColumnOptions"
            multiple
            max-tag-count="responsive"
            placeholder="Select primary key columns"
          />
        </NFormItem>

        <NFormItem path="partitionColumn" v-bind="partitionColumnProps">
          <template #label>
            <LabelWithTooltip
              label="Partition column"
              tooltip="A datetime column that is used to create different partitions when storing the asset for improving efficiency. If there are multiple datetime columns presented in the asset, please choose the one that will be most frequently used during feature engineering for loading records in the past N days. If unsure, use 'offerfit_received_ts'"
            />
          </template>
          <NSelect
            v-model:value="partitionColumn"
            :options="settings.partitionColumnOptions"
            placeholder="Select partition columns"
          />
        </NFormItem>
      </div>
    </section>
    <NDivider />
    <section
      class="preprocess-settings-card-bottom-section grid grid-cols-1 gap-6 @2xl:grid-cols-4 @xl:grid-cols-2"
    >
      <NFormItem
        path="daysBackToEnforcePrimaryKey"
        v-bind="daysBackToEnforcePrimaryKeyProps"
      >
        <template #label>
          <LabelWithTooltip
            label="Days back to enforce primary key"
            tooltip="Specifies how far back (in days) to enforce primary key uniqueness. For example, if you select 14 days, preprocessing will look back 14 days to deduplicate data."
          />
        </template>
        <NInputNumber v-model:value="daysBackToEnforcePrimaryKey" :min="1" />
      </NFormItem>

      <NFormItem
        class="@2xl:col-span-3"
        path="useRecentTimestamp"
        v-bind="useRecentTimestampProps"
      >
        <template #label>
          <LabelWithTooltip
            label="Use the most recent timestamp in case of duplicate primary keys"
            tooltip="If the asset is a Sliding window incremental asset, turn off this setting. Sliding window assets are incremental assets that contain multiple dates within each file, include duplicate records for prior event dates, and allow for updates to historical data as new events are added. They are typically used for assets that include 'late' event data."
          />
        </template>
        <NSwitch v-model:value="useRecentTimestamp" />
      </NFormItem>
    </section>
  </NCard>
</template>
