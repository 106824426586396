/* eslint-disable @typescript-eslint/naming-convention */
import type {Preset} from 'unocss'
import type {Theme} from 'unocss/preset-mini'
import {generateCssVariables} from '../generate.js'
import type {PresetOfferfitThemeOptions} from '../types.js'

/**
 * @param globals Generates global variables, like *.border-color, body.color, body.background.
 * @default true
 */
export function presetOfferfit(
  options: PresetOfferfitThemeOptions = {},
): Preset<Theme> {
  return {
    name: 'unocss-preset-offerfit',
    preflights: [
      {
        getCSS: () => `
          ${generateCssVariables(options)}
        `,
      },
    ],
    theme: {
      colors: {
        border: 'hsl(var(--of-border))',
        input: 'hsl(var(--of-input))',
        ring: 'var(--of-ring)',
        background: 'hsl(var(--of-background))',
        foreground: 'hsl(var(--of-foreground))',
        primary: {
          DEFAULT: 'hsl(var(--of-primary))',
          foreground: 'hsl(var(--of-primary-foreground))',
        },
        destructive: {
          DEFAULT: 'hsl(var(--of-destructive))',
          foreground: 'hsl(var(--of-destructive-foreground))',
        },
        muted: {
          DEFAULT: 'hsl(var(--of-muted))',
          foreground: 'hsl(var(--of-muted-foreground))',
        },
        accent: {
          DEFAULT: 'hsl(var(--of-accent))',
          foreground: 'hsl(var(--of-accent-foreground))',
          primary: 'hsl(var(--of-accent-primary))',
          'primary-foreground': 'hsl(var(--of-accent-primary-foreground))',
        },
        popover: {
          DEFAULT: 'hsl(var(--of-popover))',
          foreground: 'hsl(var(--of-popover-foreground))',
        },
        tooltip: {
          DEFAULT: 'hsl(var(--of-tooltip))',
          foreground: 'hsl(var(--of-tooltip-foreground))',
        },
        card: {
          DEFAULT: 'hsl(var(--of-card))',
          foreground: 'hsl(var(--of-card-foreground))',
          border: 'hsl(var(--of-card-border))',
        },
      },
      borderRadius: {
        xl: 'calc(var(--of-radius) + 4px)',
        lg: 'var(--of-radius)',
        md: 'calc(var(--of-radius) - 2px)',
        sm: 'calc(var(--of-radius) - 4px)',
      },
    },
  }
}
